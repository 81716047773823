








































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { Billing } from "@/model/agreement/billing";
import { USE_DIV, SEND_RECEIPT_SYSTEM_DIV } from "@/consts/const";

@Component({})
export default class AiReportInfoViewer extends Mixins(UtilMixin) {
  /** Prop */

  /** 事業所請求情報 */
  @Prop({ default: {} as Billing }) billing!: Billing;

  /** 変数 */

  /** 使用/未使用区分 */
  private USE_DIV = USE_DIV;
  /** 送る/送らない区分 */
  private SEND_RECEIPT_SYSTEM_DIV = SEND_RECEIPT_SYSTEM_DIV;

  /** 統一日付フォーマット */
  private DATE_FORMAT = "yyyy年MM月dd日";
}
