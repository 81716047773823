






























































import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";

@Component({
  components: {
    AppTextField,
  },
})
export default class PortalMailLogSearchBox extends Vue {
  private RequestUserSystemId = "";
  private PortalStaffSystemId = "";
  private MailAddress = "";
  private Keyword = "";
  private MailLogId = "";
  private CstNo = "";
  private OgtNo = "";
  private KstNo = "";

  @Emit()
  private search() {
    return {
      request_user_system_id: this.RequestUserSystemId,
      portal_staff_system_id: this.PortalStaffSystemId,
      mail_address: this.MailAddress,
      keyword: this.Keyword,
      mail_log_id: this.MailLogId,
      cst_no: this.CstNo,
      ogt_no: this.OgtNo,
      kst_no: this.KstNo,
    };
  }
}
