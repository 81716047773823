










































import { Component, Mixins, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
export default class PortalStaffSearchBox extends Mixins(UtilMixin) {
  /** 検索条件 */
  private portal_staff_name = "";
  private keyword = "";
  private portal_staff_system_id = "";
  private portal_office_name = "";
  private portal_office_code = "";
  private cst_no = "";
  private kst_no = "";

  /** 検索クリック時 */
  @Emit()
  search() {
    return {
      portal_staff_name: this.portal_staff_name,
      keyword: this.keyword,
      portal_staff_system_id: this.portal_staff_system_id,
      portal_office_name: this.portal_office_name,
      portal_office_code: this.portal_office_code,
      cst_no: this.cst_no,
      kst_no: this.kst_no,
    };
  }
}
