







































































































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Billing, DEFAULT_BILLING } from "@/model/agreement/billing";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
export default class OfficeInfoAiReportEditor extends Mixins(
  RulesMixin,
  UtilMixin
) {
  /** Prop */

  /** 事業所請求情報 */
  @Prop({ default: () => DEFAULT_BILLING }) billing!: Billing;

  /** getter */

  private get IsAiOn(): boolean {
    return (
      this.billing.is_ai_enabled == 1 ||
      this.billing.is_ai_month_report_enabled == 1
    );
  }

  /** 関数 */

  /** バリデーションチェック:開始日 */
  private checkStartDate(date: string): boolean | string {
    // 機能OFFの場合は必須なし
    if (!this.IsAiOn) {
      return true;
    }

    // 機能ONの場合は必須
    if (!date) {
      return "必須項目です。";
    }

    return true;
  }

  /** バリデーションチェック:契約・課金開始日 */
  private checkAgreementAndBillingStartDate(date: string): boolean | string {
    // 機能OFFの場合は必須なし
    if (!this.IsAiOn) {
      return true;
    }

    // 機能ONの場合は必須
    // ただし、AI自動作成の無料公開期間後（1/1）
    const dNow = this.newDate("");
    const dStart = this.newDate("2025-01-01");

    if (dNow >= dStart && !date) {
      return "必須項目です。";
    }

    return true;
  }

  /** 月次報告書AI自動作成機能（別添）リセット */
  private resetBettenEnabled() {
    if (!this.billing.is_ai_month_report_enabled) {
      this.billing.is_ai_month_report_betten_enabled = 0;
    }
  }
}
