

































import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";

export interface PortalSearchParams {
  code: string;
  name: string;
  ogt_no: string;
  kst_no: string;
  approval_statuses?: number[];
  listing_review_statuses?: number[];
}

@Component({
  components: {
    AppTextField,
  },
})
export default class PortalSearchBox extends Vue {
  private code = "";
  private name = "";
  private ogt_no = "";
  private kst_no = "";

  @Emit()
  private search(): PortalSearchParams {
    return {
      code: this.code,
      name: this.name,
      ogt_no: this.ogt_no,
      kst_no: this.kst_no,
    };
  }
}
