

































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import InfiniteLoading from "vue-infinite-loading";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm, Choice } from "@/types";
import { Agreement } from "@/model/agreement";
import { DispOperationLog } from "#/model/operationlog";

@Component({
  components: {
    InfiniteLoading,
  },
})
export default class OperationLog extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  @Ref("infiniteLoading") private readonly infinite?: InfiniteLoading;

  @Ref("form") private readonly form!: VForm;

  /** ログ表示用のテーブルヘッダ情報 */
  private headers = [
    {
      text: "実行日時",
      value: "timestamp_disp",
      align: "start",
    },
    {
      text: "実行職員",
      value: "office_staff_id",
      align: "start",
    },
    {
      text: "画面・機能名",
      value: "function_name",
      align: "start",
    },
    {
      text: "操作名",
      value: "operation_name",
      align: "start",
    },
    {
      text: "状態",
      value: "status_name",
      align: "start",
    },
    {
      text: "内容",
      value: "short_content",
      align: "start",
    },
    {
      text: "IPアドレス",
      value: "ip",
      align: "start",
    },
    {
      text: "OS",
      value: "os",
      align: "start",
    },
    {
      text: "ブラウザ",
      value: "browser",
      align: "start",
    },
    {
      text: "ログID",
      value: "log_id",
      align: "start",
    },
  ];

  /** オペログ情報 */
  private logs: DispOperationLog[] = [];

  /** 読み込み完了日時 */
  private next_timestamp = null;

  /** 開始日 */
  private start_date = this.dateToStr(new Date(), "yyyy-MM-dd");

  /** 開始時 */
  private start_hh = 0;

  /** 開始分 */
  private start_mm = 0;

  /** 終了日 */
  private end_date = this.dateToStr(new Date(), "yyyy-MM-dd");

  /** 終了時 */
  private end_hh = 23;

  /** 終了分 */
  private end_mm = 59;

  /** 選択肢用契約情報 */
  private agreements: Choice[] = [];

  /** 選択中契約ID */
  private agreementId = 0;

  /** 選択肢用事業所情報 */
  private offices: Choice[] = [];

  /** 選択中事業所ID */
  private office_id = 0;

  /** 選択肢用管理者情報 */
  private adminUsers: Choice[] = [];

  /** 選択肢用職員情報 */
  private staffs: Choice[] = [];

  /** 選択中職員ID */
  private staff_id = 0;

  /** 選択肢用利用者情報 */
  private patients: Choice[] = [];

  /** 選択中利用者ID */
  private patient_id = 0;

  /** 選択中管理者ユーザーID */
  private admin_id = 0;

  /** 選択肢用画面・機能マスタデータ */
  private functions: Choice[] = [];

  /** 選択中画面・機能ID */
  private function_id = 0;

  /** 取得系操作ログを除外するかどうかフラグ */
  private excludeGetLogs = true;

  private masterLoading = 0;

  created() {
    this.fetchAgreements();
  }

  /** 取得開始日時 */
  private get StartDatetime(): string {
    const hour = ("0" + this.start_hh).slice(-2);
    const minute = ("0" + this.start_mm).slice(-2);
    return `${this.start_date} ${hour}:${minute}`;
  }

  /** 取得終了日時 */
  private get EndDatetime(): string {
    const hour = ("0" + this.end_hh).slice(-2);
    const minute = ("0" + this.end_mm).slice(-2);
    return `${this.end_date} ${hour}:${minute}`;
  }

  /** 選択契約に変更があった場合 */
  private onChangeAgreement() {
    // 契約に紐づく事業所、職員、利用者選択肢をリセットし再取得
    this.office_id = 0;
    this.staff_id = 0;
    this.patient_id = 0;
    this.$nextTick(() => {
      this.fetchMaster();
      this.search();
    });
  }

  /** 選択事業所に変更があった場合 */
  private onChangeOffice() {
    // 事業所に紐づく職員、利用者選択肢をリセットし再取得
    this.staff_id = 0;
    this.patient_id = 0;
    this.$nextTick(() => {
      this.fetchMaster();
      this.search();
    });
  }

  /** 検索処理 */
  private async search() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.logs = [];
    this.infinite?.stateChanger.reset();
    this.next_timestamp = null;
  }

  /** 契約情報取得 */
  private fetchAgreements(): void {
    this.masterLoading++;
    this.postJsonCheck(
      window.base_url + "/api/admin/agreements/get",
      {},
      (res) => {
        if (res.data.agreements) {
          // 選択肢用に加工
          this.agreements = res.data.agreements.map((agreement: Agreement) => {
            return {
              text: agreement.co_name,
              value: agreement.id,
            };
          });
          this.masterLoading--;
        }
      }
    );
  }

  /** マスタ情報取得 */
  private fetchMaster(): void {
    this.masterLoading++;
    this.postJsonCheck(
      window.base_url + "/api/opelog/ibow2/master",
      {
        agreement_id: this.agreementId,
        office_id: this.office_id,
      },
      (res) => {
        this.offices = res.data.offices;
        this.staffs = res.data.staffs;
        this.adminUsers = res.data.admin_users;
        this.patients = res.data.patients;
        this.functions = res.data.functions;
        this.masterLoading--;
      }
    );
  }

  /** データ更新 */
  private fetchData() {
    this.postJsonCheck(
      window.base_url + "/api/opelog/ibow2/gets",
      {
        start_datetime: this.StartDatetime,
        end_datetime: this.EndDatetime,
        office_id: this.office_id,
        staff_id: this.staff_id,
        patient_id: this.patient_id,
        admin_id: this.admin_id,
        function_id: this.function_id,
        timestamp: this.next_timestamp,
        agreement_id: this.agreementId,
        exlclude_get_logs: this.excludeGetLogs,
      },
      (res) => {
        this.logs = this.logs.concat(res.data.operation_logs);
        this.next_timestamp = res.data.next_timestamp;
        this.infinite?.stateChanger.loaded();
        if (!res.data.next_timestamp) {
          this.infinite?.stateChanger.complete();
        }
      }
    );
  }

  /** 契約選択肢に関するバリデーションチェック */
  private requireAgreement(): boolean | string {
    if (this.agreementId == 0) {
      return "必須項目です。";
    }
    return true;
  }

  /** 無限スクロールハンドラ */
  private infiniteHandler() {
    this.fetchData();
  }
}
