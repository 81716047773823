












































import { Component, Mixins } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import PortalSearchBox from "@/components/portal/PortalOfficeSearchBox.vue";
import { PortalSearchParams } from "@/components/portal/PortalOfficeSearchBox.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import PortalOfficeTable from "@/views/portal/PortalOfficeTable.vue";

@Component({
  components: {
    AppTextField,
    PortalSearchBox,
    AppAuthButton,
    PortalOfficeTable,
  },
})
export default class PortalOfficeList extends Mixins(AxiosMixin) {
  private searchKey = ""; //絞り込み文字列

  /** タブ情報 */
  private currentTab = 0;

  private offices = [];
  private reviewings = [];
  private listingReviewings = [];
  private rejects = [];

  //データ取得
  private fetchList(searchObj: PortalSearchParams): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_offices/get",
      searchObj,
      (res) => {
        this.offices = res.data.portal_offices ?? [];
        this.reviewings = res.data.reviewing_portal_offices ?? [];
        this.listingReviewings =
          res.data.listing_reviewing_portal_offices ?? [];
        this.rejects = res.data.reject_portal_offices ?? [];
      }
    );
  }
}
